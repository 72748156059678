<template>
  <div>

    <section class="top">
      <div class="container">
        <a href="/#/login" title="立即登录"><i></i><p>立即登录</p></a>
        <div class="logo"><span></span><i></i></div>
      </div>
    </section>
    <section class="main">
      <div class="container">
        <div class="box">
          <div class="boxname"><span>资料审核</span></div>
        </div>
        <div class="inaudit">
          <div class="tipinfo">
            <i></i>
            <p>您的资质还在审核中</p>
            <p>请稍等哦~</p>
          </div>
          <div class="time">
            <ul>
              <i class="icon-top"></i>
              <i class="icon-bottom"></i>
              <li>审核提交</li>
              <li>提交时间：<span>{{timeAudit}}</span></li>
              <li>资质审核中...</li>
              <li>预计3个工作日内回复审核结果</li>
            </ul>
          </div>
          <div class="tips">
            <ul>
              <h1>提示</h1>
              <li>提交申请成功，请等待客服人员审核。审核通过后，登录账号和密码将会发送到旅行社邮箱，请注意查看。</li>
              <h2>若有疑问，请拨打<span>400-8196-333</span>客服热线。</h2>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footerUl></footerUl>
  </div>
</template>

<script>
  Date.prototype.Format = function (fmt) { // author: meizz
    var o = {
      "M+": this.getMonth() + 1, // 月份
      "d+": this.getDate(), // 日
      "h+": this.getHours(), // 小时
      "m+": this.getMinutes(), // 分
      "s+": this.getSeconds(), // 秒
      "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
      "S": this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  }
  export default {
    name: "inAudit",
    data() {
      return {
        timeAudit:new Date().Format("yyyy-MM-dd hh:mm:ss"),
      }
    },
    methods: {}
  }
</script>

<style scoped>
  @import "../assets/css/register.scss";
  .tips{
    margin-bottom: 76px;
  }
</style>
